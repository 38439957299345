import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import styles from "./ResendLinkActivation.module.css";
import Input from "../Input/Input";
import useForm from "../../Hooks/useForm";
import { USER_RESEND_ACTIVATION_LINK } from "../../Api";

const ResendLinkActivation = () => {
  const { token } = useParams();
  const email = useForm("email");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (email.value.trim() === "") {
      setError("Por favor, informe seu email.");
    } else {
      const { url, options } = USER_RESEND_ACTIVATION_LINK(email.value);
      const response = await fetch(url, options);

      if (response.ok) {
        alert("Email enviado com sucesso!");
        navigate("/login");
      }

      if (!response.ok) {
        const json = await response.json();
        if (json.errorCode === "NO_SUCH_ELEMENT") {
          setError("E-mail não encontrado no ListVideo App! 🤷‍♂️");
        } else if (json.errorCode === "ILLEGAL_ARGUMENT") {
          alert("Link expirado... Solicite novamente o e-mail de reativação!");
          navigate("/resend-activation");
        } else if (json.errorCode === "USER_ALREADY_ACTIVATED") {
          alert("O usuário já está ativado! Faça o login!!");
          navigate("/login");
        } else {
          console.log(json);
          setError("Ocorreu um erro ao enviar o email.");
        }
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.box}>
          <h1>📨 Reenviar e-mail de ativação</h1>
          {!token && (
            <form className={styles.form} onSubmit={handleEmailSubmit}>
              <Input
                label="Informe seu email cadastrado"
                name="email"
                type="text"
                {...email}
              />
              <button type="submit" disabled={isLoading}>
                {isLoading ? "Enviando..." : "Enviar"}
              </button>
            </form>
          )}
          {error && <p>{error}</p>}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ResendLinkActivation;
