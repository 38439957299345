import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeStorage } from "./Context/ThemeContext";
import { AutoplayStorage } from "./Context/AutoplayContext";
import { LanguageStorage } from "./Context/LanguageContext";
import { PlaylistStorage } from "./Context/PlaylistContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PlaylistStorage>
      <AutoplayStorage>
        <LanguageStorage>
          <ThemeStorage>
            <App />
          </ThemeStorage>
        </LanguageStorage>
      </AutoplayStorage>
    </PlaylistStorage>
  </React.StrictMode>
);
