import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import styles from "./PasswordSendLink.module.css";
import Input from "../Input/Input";
import useForm from "../../Hooks/useForm";
import { USER_RESET_TOKEN_PASSWORD, USER_SEND_TOKEN_PASSWORD } from "../../Api";
import PasswordShowHide from "../Input/PasswordShowHide";

const PasswordSendLink = () => {
  const { token } = useParams();
  const email = useForm("email");
  const newPassword = useForm("password");
  const confirmPassword = useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isValidToken, setIsValidToken] = useState(true);
  const [error, setError] = useState(null);

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (email.value.trim() === "") {
      setError("Por favor, informe seu email.");
    } else {
      const { url, options } = USER_SEND_TOKEN_PASSWORD(email.value);
      const response = await fetch(url, options);

      if (response.ok) {
        alert("Email enviado com sucesso!");
      }

      if (!response.ok) {
        const json = await response.json();
        console.log(json);

        if (json.errorCode === "NO_SUCH_ELEMENT") {
          setError("E-mail não encontrado no ListVideo App! 🤷‍♂️");
        } else {
          setError("Ocorreu um erro ao enviar o email.");
        }
      }
    }

    setIsLoading(false);
  };

  const handleResetPasswordSubmit = async (event) => {
    event.preventDefault();

    if (
      newPassword.value.trim() === "" ||
      confirmPassword.value.trim() === ""
    ) {
      setError("Por favor, preencha todos os campos.");
      return;
    }

    if (newPassword.value !== confirmPassword.value) {
      setError("As senhas não coincidem. Por favor, verifique novamente.");
      return;
    }

    setIsLoading(true);

    const { url, options } = USER_RESET_TOKEN_PASSWORD({
      token: token,
      newPassword: newPassword.value,
    });
    const response = await fetch(url, options);
    if (response.ok) {
      alert("Senha alterada com sucesso!");
      navigate("/login");
    }

    if (!response.ok) {
      const json = await response.json();
      if (json.errorCode === "METHOD_ARGUMENT_NOT_VALID") {
        setError("Verifique a senha informada");
      } else if (json.errorCode === "ILLEGAL_ARGUMENT") {
        alert("Link expirado... Solicite novamente o link para recuperar sua senha!");
        navigate("/forgot-password");
      } else {
        console.log(json);
        setError("Ocorreu um erro ao mudar sua senha");
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.box}>
          <h1>♻️ Recuperação de senha</h1>
          {!token && (
            <form className={styles.form} onSubmit={handleEmailSubmit}>
              <Input
                label="Informe seu email"
                name="email"
                type="text"
                {...email}
              />
              <button type="submit" disabled={isLoading}>
                {isLoading ? "Enviando..." : "Enviar"}
              </button>
            </form>
          )}
          {token && isValidToken && (
            <form className={styles.form} onSubmit={handleResetPasswordSubmit}>
              <PasswordShowHide
                label="Nova senha"
                name="newPassword"
                type="password"
                {...newPassword}
              />
              <PasswordShowHide
                label="Confirme a senha"
                name="confirmPassword"
                type="password"
                {...confirmPassword}
              />
              <button type="submit" disabled={isLoading}>
                {isLoading ? "Enviando..." : "Enviar"}
              </button>
            </form>
          )}
          {token && !isValidToken && <p>Token expirado ou inválido!</p>}
          {error && <p>{error}</p>}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PasswordSendLink;
