import React from "react";
import { useNavigate } from "react-router";
import { CERTIFICATE_POST, TOKEN_POST, USER_GET_AUTHENTICATED } from "../Api";

export const UserContext = React.createContext();

export const UserStorage = ({ children }) => {
  const [user, setUser] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const [login, setLogin] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const navigate = useNavigate();

  const getUser = async (token) => {
    if (!token) return null;
    const { url, options } = USER_GET_AUTHENTICATED(token);
    const response = await fetch(url, options);
    const user = await response.json();
    return user;
  };

  React.useEffect(() => {
    if (token) {
      window.localStorage.setItem("token", token);
    }
  }, [token]);

  React.useEffect(() => {
    if (user) {
      window.localStorage.setItem("user", user);
    }
  }, [user]);

  React.useEffect(() => {
    const autoLogin = async () => {
      const token = window.localStorage.getItem("token");
      const user = await getUser(token);
      if (user) {
        setUser(user);
        setLogin(true);
        setToken(token);
      }
    };

    autoLogin();
  }, []);

  const userLogin = async (login, password) => {
    try {
      setError(null);
      setLoading(true);

      const { url, options } = TOKEN_POST({
        login,
        password,
      });

      const response = await fetch(url, options);

      if (response.ok) {
        const json = await response.json();
        const token = json.token;
        const user = await getUser(token);

        if (user) {
          setUser(user);
          setLogin(true);
          setToken(token);
        }
      } else if (response.status === 401) {
        const json = await response.json();
        if (json.errorCode === "INVALID_LOGIN_CREDENTIALS") {
          throw new Error(`Login ou senha inválido`);
        } else if (json.errorCode === "USER_EMAIL_NOT_VERIFIED") {
          throw new Error(
            `Primeiro acesso? É necessário confirmar seu e-mail!`
          );
        }
      }
    } catch (err) {
      setError(err);
      setLogin(false);
    } finally {
      setLoading(false);
    }
  };

  async function getCertificate(playlistId, duration) {
    try {
      setLoading(true);
      const { url, options } = CERTIFICATE_POST(token, {
        playlistId: playlistId,
        durationInSeconds: duration,
      });
      const response = await fetch(url, options);
      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `certificate_${playlistId}.pdf`;
        link.click();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const userLogout = React.useCallback(
    async (messageConfirm) => {
      let confirm = window.confirm(messageConfirm);
      if (confirm) {
        setUser(null);
        setError(null);
        setLoading(false);
        setLogin(false);
        setToken(null);
        window.localStorage.removeItem("token");
        navigate("/login");
      }
    },
    [navigate]
  );

  return (
    <UserContext.Provider
      value={{
        userLogin,
        getCertificate,
        login,
        user,
        token,
        loading,
        userLogout,
        error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
